<template>
   <img :src="imgInfo.resources_img" v-if="imgInfo.resources_img!=''" :style="{'height':this.height,'width':this.width}" @click="onClickPageLink(imgInfo.link_url)"></img>
</template>

<script>
import page_links from "../../mixin/page_links";
export default {
  mixins: [ page_links ],
  props: {
    id: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      imgInfo:{},
    };
  },

  created() {
    this.getImageShowData();
  },

  mounted() {

  },

  methods: {
    async getImageShowData() {
      if( this.id){
        let {data,code} = await this.$ajax.gateway("/api/api_get_resource_by_id", {
          "METHOD": "get",
          "id": this.id
        });
        if(code==200 && data){
          this.imgInfo =data;
        }
      }
    },
  }
};
</script>

<style lang="less" scoped>
.footer {
  ._menu_footer {
    height: 1.6rem;
  }
  ._fix_footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1.6rem;
    background: #fff;
    ._menu {
      display: block;
      width: 50%;
      float: left;
      text-align: center;
      img {
        width: 0.8rem;
        margin-top: 0.4rem;
      }
    }
  }
}
.coming-soon {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  ._content {
    width: 100%;
    position: absolute;
    top: 2rem;
    left: 0;
    ._window {
      padding: 0 0.75rem;
      img {
        display: block;
        width: 100%;
      }
    }
    ._closed {
      padding: 0.4rem 0;
      text-align: center;
      img {
        width: 1.2rem;
      }
    }
  }
}
</style>
